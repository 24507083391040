import React, { useState } from 'react';

import useUserSessionData from '../../hooks/useUserSessionData';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import Button from '../../Components/Button/Button';

import NotFound from '../NotFound/NotFound';

import ArtistNameSplitter from './partials/ArtistNameSplitter';

import classes from './Admin.module.css';
import AddAlbum from './partials/AddAlbum';

function AdminPage(props: any) {
    const { userSessionData } = useUserSessionData();

    const [currentActionView, setCurrentActionView] = useState<string>();

    if (userSessionData.isReady && userSessionData.userInfo?.id?.toString() !== '1') {
        return <NotFound></NotFound>;
    }

    const onActionSelected = (event: React.MouseEvent) => {
        const action = (event.target as HTMLElement).innerText;
        setCurrentActionView(action);
    };

    return (
        <AppMainContainer backgroundColor="off-white">
            <Section>
                <Title>Actions</Title>
                <div className={classes.adminOptions}>
                    <Button viewMode="fancy" btnColor="gray" onClick={onActionSelected}>
                        ArtistNameSplitter
                    </Button>
                    <Button viewMode="fancy" btnColor="gray" onClick={onActionSelected}>
                        AddAlbum
                    </Button>
                </div>
            </Section>
            {currentActionView === 'ArtistNameSplitter' && (
                <Section>
                    <ArtistNameSplitter></ArtistNameSplitter>
                </Section>
            )}
            {currentActionView === 'AddAlbum' && (
                <Section>
                    <AddAlbum></AddAlbum>
                </Section>
            )}
        </AppMainContainer>
    );
}

export default AdminPage;
