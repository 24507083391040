import React from 'react';

import { TrackInfo } from '../../../Types/Track';

import Link from '../../Link/Link';
import ArtistLink from '../../ArtistLink/ArtistLink';
import TrackMenuButton from '../../MenuButton/TrackMenuButton';

import classes from './MusicPlayerTracklist.module.css';

export type MusicPlayerTracklistProps = {
    tracklist: TrackInfo[];
    currentTrackIndex: number;
    onPlayTrack: (trackIndex: number) => void;
    onRemoveTrack: (trackIndex: number) => void;
    onLinkClick?: () => void;
};

function MusicPlayerTracklist(props: MusicPlayerTracklistProps) {
    const { tracklist, currentTrackIndex, onPlayTrack, onRemoveTrack, onLinkClick } = props;

    return (
        <ul>
            {tracklist.map((track, index) => (
                <li key={index} className={index === currentTrackIndex ? classes.nowPlaying : ''}>
                    <div>
                        <div>
                            <Link href={`/track?tid=${track.id}`} onClick={onLinkClick}>
                                <span className="fs-105 fw-500">{track.name}</span>
                            </Link>
                        </div>
                        <ArtistLink artists={track.artists} onLinkClick={onLinkClick}></ArtistLink>
                    </div>
                    <div>
                        <button
                            className={`${classes.playerTrackslistInnerButton} fa fa-play-circle fa-xl me-75`}
                            onClick={() => {
                                onPlayTrack(index);
                            }}
                        ></button>
                        <button
                            className={`${classes.playerTrackslistInnerButton} fa fa-circle-xmark fa-xl`}
                            onClick={() => {
                                onRemoveTrack(index);
                            }}
                        ></button>
                        <TrackMenuButton
                            buttonSize="medium"
                            parentType="fixed"
                            buttonChildren={<i className="fa fa-ellipsis-v"></i>}
                            track={track}
                        ></TrackMenuButton>
                    </div>
                    <div className="fs-110">{track.videos?.[0]?.durationStr}</div>
                </li>
            ))}
        </ul>
    );
}

export default MusicPlayerTracklist;
