import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../redux/store';
import { setStatus, setViewMode, setCurrentTrack, removeTrack, playNextTrack, updateVideoDuration } from '../../redux/musicPlayerSlice';

import { MusicPlayerViewMode } from '../../Types/MusicPlayerViewMode';
import { BasicVideoInfo } from '../../Types/VideoInfo';
import { PlayerStateMode } from '../../Types/Player';
import { TrackInfo } from '../../Types/Track';
import { flagTrackVideo, updateTrackVideoDuration } from '../../Services/Tracks';
import { addUserListening } from '../../Services/User';
import useDeviceData from '../../hooks/useDeviceData';

import YouTubePlayer from '../Youtube/Youtube';
import MusicPlayerPC from './_partials/PCView/MusicPlayerPC';
import MusicPlayerMobile from './_partials/MobileView/MusicPlayerMobile';
import ListenerGuestDialog from './_partials/ListenerGuestDialog';

interface MusicPlayerPortalProps {
    children: React.ReactNode;
}

export const MusicPlayerPortal: React.FC<MusicPlayerPortalProps> = ({ children }) => {
    const portalRoot = document.getElementById('youtube-portal-root');
    return portalRoot ? ReactDOM.createPortal(children, portalRoot) : null;
};

const MusicPlayer = () => {
    const { viewMode, playStatus, currentTrackIndex, tracklist } = useSelector((state: RootState) => state.musicPlayer);
    const { userSessionData } = useSelector((state: RootState) => state.user);
    const { isMobileView } = useDeviceData();
    const dispatch = useDispatch<AppDispatch>();

    const playerObject = useRef<any>(null);
    const playerViewMode = useRef<'PC' | 'Mobile'>(isMobileView ? 'Mobile' : 'PC');
    const playerTimerInterval = useRef<NodeJS.Timer>();

    const currentPlayingTrack = useRef<TrackInfo | null>(null);
    const currentPlayingVideo = useRef<BasicVideoInfo | null>(null);
    const currentTrackTotalPlayedTime = useRef(0);
    const currentTrackAddedToListenings = useRef(false);
    const currentPlayStatus = useRef(playStatus);

    const [currentVolume, setCurrentVolume] = useState(Number(localStorage.getItem('playerVolume') ?? 50));
    const [currentVideoTime, setCurrentVideoTime] = useState(0);
    const [forceMusicPlayerReload, setForceMusicPlayerReload] = useState(false);
    const [isListenerGuestDialogVisible, setIsListenerGuestDialogVisible] = useState(false);

    const currentTrack = useMemo(
        () => (tracklist.length >= currentTrackIndex ? tracklist[currentTrackIndex] : null),
        [currentTrackIndex, tracklist]
    );
    const currentVideo = useMemo(() => (currentTrack ? currentTrack.videos?.[0] : null), [currentTrack]);
    const currentVideoDuration = useMemo(() => currentVideo?.duration ?? 0, [currentVideo?.duration]);

    const triggerGuestSignUp = useCallback(() => {
        if (userSessionData.isReady && !userSessionData.userInfo?.id) {
            const LOCAL_STORAGE_KEY = 'num_of_plays';
            const LOCAL_STORAGE_KEY_MAX_VALUE = 10;

            let keyValue = parseInt(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '0');

            if (keyValue > LOCAL_STORAGE_KEY_MAX_VALUE) {
                localStorage.setItem(LOCAL_STORAGE_KEY, '0');
                setIsListenerGuestDialogVisible(true);
                playerObject.current?.pauseVideo?.();
            } else {
                localStorage.setItem(LOCAL_STORAGE_KEY, (keyValue + 1).toString());
            }
        }
    }, [userSessionData.isReady, userSessionData.userInfo?.id]);

    const onPlayerReady = useCallback(
        (player: any) => {
            if (player) {
                playerObject.current = player;
                playerObject.current.setVolume(currentVolume);
            }
        },
        [currentVolume]
    );

    const onPlayerError = useCallback(
        (error: any) => {
            flagTrackVideo(currentVideo?.externalId ?? '', 'External');
        },
        [currentVideo?.externalId]
    );

    const onPlayerViewModeChange = (viewMode: MusicPlayerViewMode) => {
        dispatch(setViewMode(viewMode));
    };

    const onPlayerStateChange = useCallback(
        (state: PlayerStateMode) => {
            currentPlayStatus.current = state;

            if (state === 'ended') {
                resetCurrentUserListeningInfo();
                dispatch(playNextTrack());

                setTimeout(() => {
                    setForceMusicPlayerReload(true);
                }, 100);
            } else {
                dispatch(setStatus(state));
            }

            if (state === 'playing') {
                setForceMusicPlayerReload(false);
                triggerGuestSignUp();

                if (currentPlayingVideo.current && currentPlayingVideo.current.duration <= 0) {
                    const trackVideoDuration = Math.ceil(playerObject.current.getDuration());
                    currentPlayingVideo.current = { ...currentPlayingVideo.current, duration: trackVideoDuration };
                    updateTrackVideoDuration(currentPlayingVideo.current.externalId, trackVideoDuration);
                    dispatch(updateVideoDuration({ externalVideoId: currentPlayingVideo.current.externalId, duration: trackVideoDuration }));
                }
            }
        },
        [dispatch, triggerGuestSignUp]
    );

    const togglePlay = () => {
        if (playStatus !== 'buffering') {
            if (playStatus === 'playing') {
                playerObject.current.pauseVideo();
                dispatch(setStatus('paused'));
            } else {
                if (currentTrackIndex < 0) {
                    dispatch(setCurrentTrack(0));
                } else {
                    playerObject.current.playVideo();
                    dispatch(setStatus('playing'));
                }
            }
        }
    };

    const onPreviousTrack = () => {
        setCurrentVideoTime(0);
        dispatch(setCurrentTrack(currentTrackIndex > 0 ? currentTrackIndex - 1 : tracklist.length - 1));
        setTimeout(() => {
            setForceMusicPlayerReload(true);
        }, 50);
    };

    const onNextTrack = () => {
        setCurrentVideoTime(0);
        dispatch(setCurrentTrack(currentTrackIndex < tracklist.length - 1 ? currentTrackIndex + 1 : 0));
        setTimeout(() => {
            setForceMusicPlayerReload(true);
        }, 50);
    };

    const onPlayTrack = (index: number) => {
        playerObject.current.stopVideo();
        dispatch(setCurrentTrack(index));
        setTimeout(() => {
            setForceMusicPlayerReload(true);
            playerObject.current.playVideo();
        }, 50);
    };

    const onRemoveTrack = (index: number) => {
        dispatch(removeTrack(index));
    };

    const onVolumeChange = (volume: number, isMuted: boolean = false) => {
        if (playerObject.current) {
            setCurrentVolume(volume);
            playerObject.current.setVolume(volume);
            localStorage.setItem('playerVolume', volume.toString());

            if (isMuted) {
                playerObject.current.mute();
            } else if (volume > 0 && playerObject.current.isMuted()) {
                playerObject.current.unMute();
            }
        }
    };

    const onSeekToTime = (timePrecent: number) => {
        const currentPlayingVideoDuration = currentPlayingVideo.current?.duration ?? 0;

        if (playerObject.current && currentPlayingVideoDuration > 0) {
            const currentTime = (currentPlayingVideoDuration * timePrecent) / 100;
            playerObject.current.seekTo(currentTime, true);
        }
    };

    const resetCurrentUserListeningInfo = () => {
        currentTrackTotalPlayedTime.current = 0;
        currentTrackAddedToListenings.current = false;
        setCurrentVideoTime(0);
    };

    useEffect(() => {
        if (viewMode !== 'none' && !playerTimerInterval.current) {
            playerTimerInterval.current = setInterval(() => {
                if (currentPlayStatus.current === 'playing' && currentPlayingTrack.current?.id && currentPlayingVideo.current) {
                    //console.log('playerTimerInterval', playerObject.current.getCurrentTime());
                    setCurrentVideoTime(playerObject.current.getCurrentTime());

                    currentTrackTotalPlayedTime.current += 0.1;
                    //console.log(currentPlayingTrack.current?.id, currentTrackTotalPlayedTime.current);

                    if (
                        !currentTrackAddedToListenings.current &&
                        currentPlayingTrack.current?.id &&
                        currentPlayingVideo.current.duration > 0 &&
                        currentTrackTotalPlayedTime.current / currentPlayingVideo.current.duration > 0.33
                    ) {
                        currentTrackAddedToListenings.current = true;
                        //console.log('OnAddUserListening', currentPlayingTrack.current.id);
                        addUserListening(currentPlayingTrack.current.id, currentPlayingTrack.current.albumId);
                    }
                }
            }, 100);
        }

        return () => {
            //viewMode !== 'none' && playerTimerInterval.current && clearInterval(playerTimerInterval.current);
        };
    }, [viewMode]);

    useEffect(() => {
        currentPlayingTrack.current = currentTrack;
        resetCurrentUserListeningInfo();
    }, [currentTrack]);

    useEffect(() => {
        currentPlayingVideo.current = currentVideo ?? null;
    }, [currentVideo]);

    useEffect(() => {
        if (!playerObject.current) {
            playerViewMode.current = isMobileView ? 'Mobile' : 'PC';
        }
    }, [isMobileView]);

    useEffect(() => {
        currentPlayStatus.current = playStatus;

        setTimeout(() => {
            // Make sure player is playing if status changed by external event (i.e Rudux)
            if (playStatus === 'playing') {
                playerObject.current.playVideo();
            }
        }, 100);
    }, [playStatus]);

    useEffect(() => {
        const onBeforeUnload = (event: BeforeUnloadEvent) => {
            const isPlaying = currentPlayStatus.current === 'playing' && !playerObject.current.isMuted();

            if (isPlaying) {
                event.preventDefault();
                return 'Player still playing. Do you really want to close?';
            }
        };

        window.addEventListener('beforeunload', onBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, []);

    const youTubePlayer = React.useMemo(
        () => (
            <YouTubePlayer
                videoId={currentVideo?.externalId ?? ''}
                forceReload={forceMusicPlayerReload}
                onReady={onPlayerReady}
                onError={onPlayerError}
                onStateChange={onPlayerStateChange}
            />
        ),
        [currentVideo?.externalId, forceMusicPlayerReload, onPlayerReady, onPlayerError, onPlayerStateChange]
    );

    const MusicPlayerView = playerViewMode.current === 'Mobile' ? MusicPlayerMobile : MusicPlayerPC;

    if (viewMode === 'none') {
        return null;
    }

    return (
        <>
            <MusicPlayerView
                viewMode={viewMode}
                volumeValue={currentVolume}
                timeValue={currentVideoTime}
                timeDuration={currentVideoDuration}
                tracklist={tracklist}
                currentTrackIndex={currentTrackIndex}
                playStatus={playStatus}
                onPlayPause={togglePlay}
                onPreviousTrack={onPreviousTrack}
                onNextTrack={onNextTrack}
                onPlayTrack={onPlayTrack}
                onRemoveTrack={onRemoveTrack}
                onPlayerViewModeChange={onPlayerViewModeChange}
                onVolumeChange={onVolumeChange}
                onTimeChange={onSeekToTime}
            >
                {youTubePlayer}
            </MusicPlayerView>

            <ListenerGuestDialog
                isVisible={isListenerGuestDialogVisible}
                onCloseDialog={() => {
                    setIsListenerGuestDialogVisible(false);
                }}
            ></ListenerGuestDialog>
        </>
    );
};

export default MusicPlayer;
