import React, { useEffect, useState } from 'react';

import { BELLINO_BASE_URL, capitalizeWords, FetchApiData } from '../../../Classes/Utilities';
import { Artist } from '../../../Classes/Artist';

import Button from '../../../Components/Button/Button';
import Title from '../../../Components/Title/Title';
import Link from '../../../Components/Link/Link';

type SplittedArtist = {
    checked: boolean;
    artistInfo: Artist;
    missing?: boolean;
};

type HistoryResult = {
    srcArtist: Artist;
    destArtists: SplittedArtist[];
    success: boolean;
};

function ArtistNameSplitter() {
    const [artistsToSplit, setArtistsToSplit] = useState<Artist[]>([]);
    const [artistsHistory, setArtistsHistory] = useState<HistoryResult[]>([]);
    const [sourceArtist, setSourceArtist] = useState<Artist>();
    const [destArtist, setDestArtist] = useState<SplittedArtist[]>([]);

    useEffect(() => {
        fetchArtistsToSplit();
    }, []);

    useEffect(() => {
        if (sourceArtist) {
            const srcArtistName = sourceArtist.name
                .toLowerCase()
                .replaceAll(' ft. ', ' feat. ')
                .replaceAll(' with ', ' feat. ')
                .replaceAll(' + ', ' feat. ')
                .replaceAll(' vs. ', ' feat. ')
                .replaceAll(' vs ', ' feat. ')
                .replaceAll(' pres. ', ' feat. ');

            const spliitedParts = srcArtistName.split('feat.');

            const spliitedArtistsNames = [];

            if (spliitedParts.length > 0) {
                spliitedArtistsNames.push(spliitedParts[0].trim());
                spliitedArtistsNames.push(...spliitedParts[0].split(/,|&/).map(str => str.trim()));

                if (spliitedParts.length > 1) {
                    for (let i = 1; i < spliitedParts.length; i++) {
                        spliitedArtistsNames.push(spliitedParts[i].trim());
                        spliitedArtistsNames.push(...spliitedParts[i].split(',').map(str => str.trim()));
                        spliitedArtistsNames.push(...spliitedParts[i].split(/,|&/).map(str => str.trim()));
                    }
                }
            }

            setDestArtist(
                orderSplittedArtistsByName(
                    // Remove duplicates using "Set" + convert string array to "SplittedArtist" array
                    Array.from(new Set(spliitedArtistsNames)).map((an, index) => {
                        return { index, checked: true, artistInfo: new Artist({ name: an.trim() }) };
                    })
                )
            );
        }
    }, [sourceArtist]);

    const fetchArtistsToSplit = () => {
        FetchApiData(`${BELLINO_BASE_URL}/api/manager?action=GetArtistsToSplit`).then((response: any) => {
            if (response.success && response.result?.length) {
                const artistsResult: Artist[] = response.result.map((a: any) => {
                    return new Artist({ id: a.ID, name: a.Name /*, genres: a.Genre,*/ });
                });
                setArtistsToSplit(artistsResult);
                setSourceArtist(artistsResult[0]);
            }
        });
    };

    const orderSplittedArtistsByName = (array: SplittedArtist[]) => {
        return array.sort((a, b) => a.artistInfo.name.localeCompare(b.artistInfo.name));
    };

    const onNextArtist = async () => {
        const currentIndex = artistsToSplit.findIndex(a => a.id === sourceArtist?.id);

        if (0 <= currentIndex && currentIndex < artistsToSplit.length - 1) {
            setSourceArtist(artistsToSplit[currentIndex + 1]);
        } else if (currentIndex < 0) {
            setSourceArtist(artistsToSplit[0]);
        } else {
            fetchArtistsToSplit();
        }
    };

    const onPrepareData = async () => {
        try {
            const selectedDestArtist = destArtist.filter(da => da.checked)?.map(da => da.artistInfo);
            const fetchPromises = selectedDestArtist.map(sa =>
                FetchApiData(`${BELLINO_BASE_URL}/api/artists?action=GetByName&name=${encodeURIComponent(sa.name)}`)
            );

            const res = await Promise.all(fetchPromises);

            setDestArtist(prevDestArtist => {
                const updatedArtists: SplittedArtist[] = prevDestArtist.reduce((acc, da) => {
                    if (!da.checked) {
                        // If the artist is not checked, keep it as-is.
                        acc.push(da);
                        return acc;
                    }

                    const matchingArtist: any = res.find(
                        (r: any) =>
                            r.success &&
                            r.result?.some((artist: any) => artist?.Variations?.toLowerCase()?.includes(`;${da.artistInfo.name.toLowerCase()};`))
                    );

                    if (matchingArtist?.result) {
                        // If matching artists are found, add each to the updated array.
                        matchingArtist.result.forEach((artist: any) => {
                            acc.push({
                                ...da,
                                artistInfo: new Artist({ id: artist.ID, name: artist.Name, genres: artist.Genre?.split(';') })
                            });
                        });
                    } else {
                        // If no match is found, keep the original artist.
                        acc.push({ ...da, missing: true });
                    }

                    return acc;
                }, [] as SplittedArtist[]);

                // Return the sorted array.
                return orderSplittedArtistsByName(updatedArtists);
            });
        } catch (error) {
            console.error('Error in onPrepareData:', error);
        }
    };

    const onAddMissingArtists = async () => {
        const newArtistToAdd = destArtist
            .filter(da => da.checked && da.missing && da.artistInfo?.name)
            ?.map(da => capitalizeWords(da.artistInfo.name));

        const requestsPromises = newArtistToAdd.map(artistName =>
            FetchApiData(`${BELLINO_BASE_URL}/api/artists?action=Add&name=${encodeURIComponent(artistName)}`)
        );

        const res = await Promise.all(requestsPromises);

        res.forEach((requestRes: any, index: number) => {
            const artistName = newArtistToAdd[index];
            const artistId = requestRes?.success ? requestRes.result : undefined;

            if (artistId) {
                setDestArtist(prevDestArtist =>
                    orderSplittedArtistsByName([
                        ...prevDestArtist.filter(a => a.missing !== true || a.artistInfo.name.toLowerCase() !== artistName.toLowerCase()),
                        ...prevDestArtist
                            .filter(a => a.missing === true && a.artistInfo.name.toLowerCase() === artistName.toLowerCase())
                            .map(a => {
                                return { ...a, missing: false, artistInfo: new Artist({ name: artistName, id: artistId }) };
                            })
                    ])
                );
            }
        });
    };

    const onApplySplit = async () => {
        const selectedDestArtist = destArtist.filter(da => da.checked && da.artistInfo?.id)?.map(da => da.artistInfo);

        if (sourceArtist?.id && selectedDestArtist.length > 1) {
            const srcArtistID = sourceArtist.id;
            const dstArtistIDs = selectedDestArtist.map(sa => sa.id).join(';');

            console.log('srcArtistID = ', srcArtistID, 'dstArtistIDs = ', dstArtistIDs);

            FetchApiData(`${BELLINO_BASE_URL}/api/manager?action=SplitArtist&srcArtistID=${srcArtistID}&dstArtistIDs=${dstArtistIDs}`).then(
                (response: any) => {
                    if (response.success && response.result) {
                        setArtistsHistory(prevHistory => [...prevHistory, { success: true, srcArtist: sourceArtist, destArtists: destArtist }]);
                        onNextArtist();
                    }
                }
            );
        }
    };

    return (
        <>
            <Title>Artist Name Splitter</Title>
            {sourceArtist?.id && (
                <div>
                    <div>
                        <b>Source Artist Name:</b>
                    </div>
                    <div>
                        <Link target="_blank" textColor="blue" href={`/artist?aid=${sourceArtist.id}`}>
                            {sourceArtist.name}
                        </Link>
                    </div>
                    <br />
                    <div>
                        <b>Splitted Artist Names:</b>
                    </div>
                    <div>
                        {destArtist.map((artist, index) => (
                            <div>
                                <span style={{ display: 'inline-flex', whiteSpace: 'nowrap', paddingInlineStart: '1rem' }}>
                                    <input
                                        type="checkbox"
                                        checked={artist.checked}
                                        id={artist.artistInfo.id}
                                        name={artist.artistInfo.name}
                                        value={artist.artistInfo.name}
                                        style={{ marginRight: '1rem' }}
                                        onClick={() => {
                                            setDestArtist(prev => {
                                                return orderSplittedArtistsByName([
                                                    ...prev.filter((a, pi) => pi !== index),
                                                    { ...artist, checked: !artist.checked }
                                                ]);
                                            });
                                        }}
                                    />
                                    {artist.artistInfo.id ? (
                                        <Link target="_blank" textColor="blue" href={`/artist?aid=${artist.artistInfo.id}`}>
                                            {artist.artistInfo.name}
                                        </Link>
                                    ) : (
                                        artist.artistInfo.name
                                    )}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {sourceArtist && (
                <div className="mt-100">
                    <Button className="me-75" onClick={onNextArtist}>
                        Next Artist
                    </Button>
                    <Button className="me-75" onClick={onPrepareData}>
                        Prepare Data
                    </Button>
                    <Button
                        className="me-75"
                        isDisabled={!destArtist.filter(da => da.checked && da.missing && da.artistInfo?.name).length}
                        onClick={onAddMissingArtists}
                    >
                        Add Missing Artists
                    </Button>
                    <Button
                        isDisabled={
                            !sourceArtist?.id ||
                            destArtist.filter(da => da.checked && !da.artistInfo?.id).length > 0 ||
                            destArtist.filter(da => da.checked && da.artistInfo?.id).length < 2
                        }
                        onClick={onApplySplit}
                    >
                        Apply Split
                    </Button>
                </div>
            )}
            {Boolean(artistsHistory.length) && (
                <div>
                    <Title>History</Title>
                    {artistsHistory.map(artistHistory => (
                        <div className="mb-75" style={{ backgroundColor: artistHistory.success ? '#ccf1cc' : '#f1cccc' }}>
                            <Link target="_blank" textColor="blue" href={`/artist?aid=${artistHistory.srcArtist.id}`}>
                                {artistHistory.srcArtist.name}
                            </Link>
                            <span>{` => `}</span>
                            {artistHistory.destArtists
                                .filter(da => da.checked)
                                .map(artist => (
                                    <>
                                        <Link target="_blank" textColor="blue" href={`/artist?aid=${artist.artistInfo.id}`}>
                                            {artist.artistInfo.name}
                                        </Link>
                                        <span>, </span>
                                    </>
                                ))}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

export default ArtistNameSplitter;
