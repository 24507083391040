import React from 'react';
import { PlayerStateMode } from '../../../Types/Player';

export type PlayControlButtonsProps = {
    playStatus: PlayerStateMode;
    onPlayPause: () => void;
    onPreviousTrack: () => void;
    onNextTrack: () => void;
};

function PlayControlButtons(props: PlayControlButtonsProps) {
    const { playStatus, onPlayPause, onPreviousTrack, onNextTrack } = props;

    const getButtonIconByStatus = (): string => {
        if (playStatus === 'buffering') {
            return 'fa-pause';
            //return 'fa-spinner spin';
        } else if (playStatus === 'playing') {
            return 'fa-pause';
        }

        return 'fa-play';
    };

    return (
        <>
            <button className={`fa fa-lg fa-backward-step`} onClick={onPreviousTrack}></button>
            <button onClick={onPlayPause}>
                <i className={`fa fa-lg ${getButtonIconByStatus()}`}></i>
            </button>
            <button className={`fa fa-lg fa-forward-step`} onClick={onNextTrack}></button>
        </>
    );
}

export default PlayControlButtons;
