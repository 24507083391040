import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { playPlaylist } from '../../redux/musicPlayerSlice';
import { AppDispatch } from '../../redux/store';
import { setActiveDialog } from '../../redux/uiSlice';

import { BELLINO_BASE_URL, DEFAULT_RELEASE_IMG, calculatePercentile, FetchPageData } from '../../Classes/Utilities';
import { AlbumInfo } from '../../Types/Album';
import { Album } from '../../Classes/Album';
import { MusicStatistics } from '../../Types/MusicStatistics';
import { CommentInfo } from '../../Types/CommentInfo';

import useUserSessionData from '../../hooks/useUserSessionData';
import useDeviceData from '../../hooks/useDeviceData';
import usePageMetdata from '../../hooks/usePageMetdata';

import NotFound from '../NotFound/NotFound';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import Tag from '../../Components/Tag/Tag';
import Image from '../../Components/Image/Image';
import Link from '../../Components/Link/Link';
import ArtistLink from '../../Components/ArtistLink/ArtistLink';
import MenuButton from '../../Components/MenuButton/MenuButton';
import ChartBar from '../../Components/Charts/ChartBar/ChartBar';
import MusicItemStatistics from '../../Components/MusicStatistics/MusicStatistics';
import ItemPlaybar from '../../Components/ItemPlaybar/ItemPlaybar';
import UsersComments from '../../Components/UsersComments/UsersComments';

import FriendsListenersDialog, { FriendsListener } from '../_partials/FriendsListenersDialog';
import RateDialog from '../_partials/RateDialog';
import RecommendDialog from '../_partials/RecommendDialog';
import ImproveMetadataDialog from '../_partials/ImproveMetadataDialog';

import classes from './Album.module.css';

function AlbumPage(props: any) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const albumID = searchParams.get('aid');

    const { userSessionData } = useUserSessionData();
    const { isMobileView } = useDeviceData();
    const { updatePageMetdata } = usePageMetdata();
    const dispatch = useDispatch<AppDispatch>();

    const isLoading = useRef<boolean>();

    const [album, setAlbum] = useState<Album>(new Album());
    const [albumStatistics, setAlbumStatistics] = useState<MusicStatistics>();
    const [comments, setComments] = useState<CommentInfo[]>([]);

    const [isFriendsListenersDialog, setIsFriendsListenersDialog] = useState(false);
    const [isRateDialogVisible, setIsRateDialogVisible] = useState(false);
    const [isRecommendDialogVisible, setIsRecommendDialogVisible] = useState(false);
    const [isImproveMetadataDialogVisible, setIsImproveMetadataDialogVisible] = useState(false);

    useEffect(() => {
        if (!isLoading.current && albumID) {
            isLoading.current = true;
            setAlbum(new Album());

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/album?id=${albumID}`).then((response: any) => {
                if (response.success) {
                    updatePageMetdata(response.result?.metadata);
                    setAlbum(new Album({ ...(response.result?.albumInfo as AlbumInfo), tracks: response.result?.tracks }));
                    setAlbumStatistics({
                        listeners: response.result?.albumStatistics?.listeners,
                        plays: response.result?.albumStatistics?.plays,
                        myPlays: response.result?.albumStatistics?.myPlays,
                        friendsPlays: response.result?.albumStatistics?.friendsPlays,
                        rating: undefined
                    });
                    setComments(response.result?.comments);
                } else {
                    setAlbum(new Album());
                }

                setTimeout(() => {
                    isLoading.current = false;
                }, 100);
            });
        }
    }, [albumID, updatePageMetdata]);

    const friendsListeners = useMemo(
        () =>
            (albumStatistics?.friendsPlays ?? [])
                .map(friendPlays => {
                    return {
                        userId: friendPlays.UserID,
                        itemType: 'Album',
                        itemID: albumID,
                        itemNumOfPlays: friendPlays.Plays
                    } as FriendsListener;
                })
                .sort((f1, f2) => {
                    return f2.itemNumOfPlays - f1.itemNumOfPlays;
                }),
        [albumID, albumStatistics?.friendsPlays]
    );

    const maxTrackScore = useMemo(() => Math.max(...album.tracks.map(albumTrack => albumTrack.score)), [album.tracks]);
    const isMultipleAlbumCds = album.cdTracksInfo.length > 1;

    if (!albumID || (!album?.isInit && typeof isLoading.current !== 'undefined' && !isLoading.current)) {
        return <NotFound></NotFound>;
    }

    const onPlayAlbum = async (playMode: 'normal' | 'highlights') => {
        if (playMode === 'normal') {
            const albumPlaylist = album.tracks.filter(albumTrack => albumTrack.videos?.length);

            if (albumPlaylist.length > 0) {
                dispatch(playPlaylist(albumPlaylist));
            } else {
                dispatch(setActiveDialog({ activeDialog: 'NONE', activeDialogData: 'Album' }));
            }
        } else {
            const albumTracksMinHighlightScore = calculatePercentile(album.tracks.map(albumTrack => albumTrack.score).filter(Boolean), 60);
            const albumHighlightsPlaylist = albumTracksMinHighlightScore
                ? album.tracks.filter(albumTrack => albumTrack.videos?.length && albumTrack.score >= albumTracksMinHighlightScore)
                : [];

            if (albumHighlightsPlaylist.length > 0) {
                dispatch(playPlaylist(albumHighlightsPlaylist));
            } else {
                dispatch(setActiveDialog({ activeDialog: 'NONE', activeDialogData: 'Album' }));
            }
        }
    };

    return (
        <AppMainContainer showPreloader={!album.isInit}>
            <Section backgroundColor="gradient-opacity-white">
                <div className={classes.albumInfoContainer}>
                    <div>
                        <Link href={`/photos?type=album&id=${album.id}`}>
                            <Image
                                src={album.coverImgPath}
                                srcFallback={DEFAULT_RELEASE_IMG}
                                viewWidth={isMobileView ? 280 : 350}
                                viewHeight={isMobileView ? 280 : 350}
                                viewMode="background-image"
                                imageClass={'l-shadow'}
                            ></Image>
                        </Link>
                    </div>
                    <div>
                        <div>
                            <div className={classes.albumTitle}>{album.name}</div>
                            <div className="fs-160">
                                <ArtistLink artists={album.artists} textColor={'dark-blue'}></ArtistLink>
                            </div>
                            <div className="text-gray mt-100">{album.date}</div>
                            <div className="mt-100 mb-100">
                                {album.genres.map(albumGenre => (
                                    <Tag key={albumGenre} viewMode="default" text={albumGenre} href={`/genre?name=${albumGenre}`}></Tag>
                                ))}
                            </div>
                            {albumStatistics && albumStatistics.plays > 10 ? (
                                <MenuButton
                                    buttonCustomClass={classes.albumPlayButton}
                                    buttonChildren={
                                        <>
                                            <i id="play_radio_icon" className="fa fa-play-circle fa-lg me-50"></i>
                                            Play Album
                                        </>
                                    }
                                    menuSections={[
                                        {
                                            menuItems: [
                                                {
                                                    text: 'Play full album',
                                                    onClick: () => {
                                                        onPlayAlbum('normal');
                                                    }
                                                },
                                                {
                                                    text: 'Play highlights tracks',
                                                    onClick: () => {
                                                        onPlayAlbum('highlights');
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                ></MenuButton>
                            ) : (
                                <button
                                    id="play_album_btn"
                                    className={classes.albumPlayButton}
                                    onClick={() => {
                                        onPlayAlbum('normal');
                                    }}
                                >
                                    <i id="play_radio_icon" className="fa fa-play-circle fa-lg me-50"></i>
                                    Play Album
                                </button>
                            )}
                        </div>
                        <div className={classes.musicStatistics}>
                            <MusicItemStatistics
                                numOfListeners={albumStatistics?.listeners ?? 0}
                                numOfPlays={albumStatistics?.plays ?? 0}
                                numOfMyPlays={albumStatistics?.myPlays ?? 0}
                                numOfMyFriendsPlays={albumStatistics?.friendsPlays ? Object.keys(albumStatistics.friendsPlays).length : 0}
                                onMyFriendsPlaysClick={() => {
                                    setIsFriendsListenersDialog(true);
                                }}
                                onMyPlaysClick={() => {
                                    navigate(`/listenings?type=album&itemID=${albumID}`);
                                }}
                            ></MusicItemStatistics>
                        </div>
                    </div>
                </div>
                <MenuButton
                    buttonView="white-circle"
                    buttonSize="large"
                    containerCustomClass={classes.albumMenuBtn}
                    menuSections={[
                        {
                            menuItems: [
                                { text: 'Buy album on Amazon', href: album.amazonBuyLink, hrefTarget: '_blank' },
                                {
                                    text: 'Rate album',
                                    onClick: () => {
                                        setIsRateDialogVisible(true);
                                    }
                                },
                                {
                                    text: 'Recommend to friends',
                                    onClick: () => {
                                        setIsRecommendDialogVisible(true);
                                    }
                                },
                                {
                                    text: 'Improve metadata',
                                    onClick: () => {
                                        setIsImproveMetadataDialogVisible(true);
                                    }
                                }
                            ]
                        }
                    ]}
                ></MenuButton>
            </Section>

            <Section backgroundColor="white">
                <Title>Tracklist</Title>
                {album.cdTracksInfo.map(cdInfo => {
                    return (
                        <div key={cdInfo.number} className={isMultipleAlbumCds ? 'mb-100' : ''}>
                            {isMultipleAlbumCds && <div className={classes.cdNumber}>CD {cdInfo.number}</div>}
                            <table className={classes.trackList}>
                                <tbody>
                                    {cdInfo.tracks.map(albumTrack => (
                                        <tr key={albumTrack.id}>
                                            <td>{albumTrack.position}</td>
                                            <td>
                                                <ItemPlaybar
                                                    itemType="Track"
                                                    itemId={albumTrack.id}
                                                    trackInfo={albumTrack}
                                                    customCSS={{ color: '#777' }}
                                                >
                                                    {album.isVariousArtistsAlbum && (
                                                        <>
                                                            <ArtistLink artists={albumTrack.artists}></ArtistLink>
                                                            <span> - </span>
                                                        </>
                                                    )}
                                                    <Link href={`/track?tid=${albumTrack.id}`}>{albumTrack.name}</Link>
                                                    {Boolean(
                                                        !album.isVariousArtistsAlbum && albumTrack.artists.length > album.artists.length
                                                    ) && (
                                                        <span className="text-gray">
                                                            <span> (With </span>
                                                            <ArtistLink
                                                                artists={albumTrack.artists.filter(
                                                                    ta => !album.artists.find(aa => aa.name === ta.name)
                                                                )}
                                                            ></ArtistLink>
                                                            <span>)</span>
                                                        </span>
                                                    )}
                                                </ItemPlaybar>
                                            </td>
                                            <td>
                                                <ChartBar
                                                    score={(albumTrack.score / maxTrackScore) * 100}
                                                    text={albumTrack.score.toString()}
                                                ></ChartBar>
                                            </td>
                                            <td>{albumTrack.durationStr}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className={classes.totalTime}>
                                <span>{cdInfo.totalDurationStr}</span>
                            </div>
                        </div>
                    );
                })}
            </Section>

            <Section backgroundColor="white">
                <Title>Comments</Title>
                <div>
                    <UsersComments
                        itemType="Album"
                        itemId={albumID}
                        comments={comments as CommentInfo[]}
                        activeUserInputComment={
                            userSessionData.userInfo
                                ? {
                                      userId: userSessionData.userInfo.id,
                                      userFirstName: userSessionData.userInfo.firstName,
                                      userLastName: userSessionData.userInfo.lastName,
                                      userImgPath: userSessionData.userInfo.imgPath ?? ''
                                  }
                                : undefined
                        }
                        onAddComment={commentInfo => {
                            setComments(prevComments => [...prevComments, commentInfo]);
                        }}
                        onDeleteComment={commentId => {
                            setComments(prevComments => {
                                return prevComments.filter(c => c.id.toString() !== commentId.toString());
                            });
                        }}
                    ></UsersComments>
                </div>
            </Section>

            <RateDialog
                itemType="Album"
                itemId={albumID}
                isVisible={isRateDialogVisible}
                onCloseDialog={() => setIsRateDialogVisible(false)}
            ></RateDialog>

            <FriendsListenersDialog
                friendsListeners={friendsListeners}
                isVisible={isFriendsListenersDialog}
                onCloseDialog={() => {
                    setIsFriendsListenersDialog(false);
                }}
            ></FriendsListenersDialog>

            <RecommendDialog
                itemType="Album"
                itemId={albumID}
                isVisible={isRecommendDialogVisible}
                onCloseDialog={() => setIsRecommendDialogVisible(false)}
            ></RecommendDialog>

            <ImproveMetadataDialog
                itemType="Album"
                itemId={albumID}
                isVisible={isImproveMetadataDialogVisible}
                onSave={(metdaDataType, data) => {
                    console.log(metdaDataType, data);
                }}
                onCloseDialog={() => setIsImproveMetadataDialogVisible(false)}
            ></ImproveMetadataDialog>
        </AppMainContainer>
    );
}

export default AlbumPage;
