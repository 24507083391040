import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { RootState } from './redux/store';

import IndexPage from './Pages/Index/Index';
import Home from './Pages/Home/Home';
import NewReleases from './Pages/NewReleases/NewReleases';
import ArtistPage from './Pages/Artist/Artist';
import VariousArtistsPage from './Pages/VariousArtists/VariousArtists';
import ArtistCharts from './Pages/ArtistCharts/ArtistCharts';
import AlbumPage from './Pages/Album/Album';
import TrackPage from './Pages/Track/Track';
import Users from './Pages/Users/Users';
import Discography from './Pages/Discography/Discography';
import UserPage from './Pages/User/User';
import Charts from './Pages/Charts/Charts';
import UserCharts from './Pages/UserCharts/UserCharts';
import UserListeningsPage from './Pages/UserListenings/UserListenings';
import UserRatedItemsPage from './Pages/UserRatedItems/UserRatedItems';
import UsersPlaylistsPage from './Pages/UsersPlaylists/UsersPlaylists';
import UsersMessagesPage from './Pages/UsersMessages/UsersMessages';
import AccountPage from './Pages/Account/Account';
import PhotosPage from './Pages/Photos/Photos';
import GenrePage from './Pages/Genre/Genre';
import Friends from './Pages/Friends/Friends';
import RadioPage from './Pages/Radio/Radio';
import NotFound from './Pages/NotFound/NotFound';
import SignUp from './Pages/SignUp/SignUp';
import LogoutPage from './Pages/Logout/Logout';
import ForgotPasswordPage from './Pages/ForgotPassword/ForgotPassword';
import TermsPage from './Pages/Terms/Terms';
import PrivacyPage from './Pages/Privacy/Privacy';
import AdminPage from './Pages/Admin/Admin';

import MusicPlayer, { MusicPlayerPortal } from './Components/MusicPlayer/MusicPlayer';

import './App.css';

function App() {
    const { viewHeight } = useSelector((state: RootState) => state.musicPlayer);

    return (
        <div style={{ marginBottom: viewHeight }}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<IndexPage />} />
                    <Route path="/home" element={<Home />}></Route>
                    <Route path="/newreleases" element={<NewReleases />}></Route>
                    <Route path="/album" element={<AlbumPage />}></Route>
                    <Route path="/artist" element={<ArtistPage />}></Route>
                    <Route path="/variousartists" element={<VariousArtistsPage />}></Route>
                    <Route path="/artistcharts" element={<ArtistCharts />}></Route>
                    <Route path="/track" element={<TrackPage />}></Route>
                    <Route path="/discography" element={<Discography />}></Route>
                    <Route path="/charts" element={<Charts />}></Route>
                    <Route path="/radio" element={<RadioPage />}></Route>
                    <Route path="/genre" element={<GenrePage />}></Route>
                    <Route path="/photos" element={<PhotosPage />}></Route>
                    <Route path="/user" element={<UserPage />}></Route>
                    <Route path="/account" element={<AccountPage />}></Route>
                    <Route path="/usercharts" element={<UserCharts />}></Route>
                    <Route path="/listenings" element={<UserListeningsPage />}></Route>
                    <Route path="/ratings" element={<UserRatedItemsPage />}></Route>
                    <Route path="/playlists" element={<UsersPlaylistsPage />}></Route>
                    <Route path="/messages" element={<UsersMessagesPage />}></Route>
                    <Route path="/users" element={<Users />}></Route>
                    <Route path="/friends" element={<Friends />}></Route>
                    <Route path="/signup" element={<SignUp />}></Route>
                    <Route path="/logout" element={<LogoutPage />}></Route>
                    <Route path="/forgot_password" element={<ForgotPasswordPage />}></Route>
                    <Route path="/terms" element={<TermsPage />}></Route>
                    <Route path="/privacy" element={<PrivacyPage />}></Route>
                    <Route path="/admin" element={<AdminPage />}></Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <MusicPlayerPortal>
                    <MusicPlayer></MusicPlayer>
                </MusicPlayerPortal>
            </BrowserRouter>
        </div>
    );
}

export default App;
