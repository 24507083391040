import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Link.module.css';

export type LinkProps = {
    href: string;
    target?: '_blank' | '_self' | '_parent' | '_top';
    viewMode?: 'Button' | 'See-More-Hover';
    textColor?: string;
    bgColor?: 'blue' | 'green';
    children?: React.ReactNode;
    onClick?: () => void;
};

function LinkComp(props: LinkProps) {
    const { href, viewMode, textColor, children, onClick } = props;

    const target = props?.target ?? '_self';
    let className = textColor ? 'text-' + textColor : '';

    if (viewMode === 'Button') {
        const bgColor = props?.bgColor ?? 'blue';
        className += ` ${classes.buttonView} ${classes[`${bgColor}ButtonView`]}`;
    } else if (viewMode === 'See-More-Hover') {
        className += ` ${classes[`SeeMoreView`]}`;
    }

    return (
        <Link to={href} target={target} className={className} rel={target === '_blank' ? 'noreferrer' : ''} onClick={onClick}>
            {children}
        </Link>
    );
}

export default LinkComp;
