import React from 'react';
import classes from './MusicStatistics.module.css';

const numberShorten = (num: number, precision = 1) => {
    if (num <= 999) return num.toLocaleString();

    if (num < 1000000) return `${(num / 1000).toFixed(precision)}K`.replace('.0', '');

    return `${(num / 1000000).toFixed(precision)}M`.replace('.0', '');
};

/* Music Item Statistics */

export type MusicItemStatisticsProps = {
    numOfListeners: number;
    numOfPlays: number;
    numOfMyPlays?: number;
    numOfMyFriendsPlays?: number;
    onMyPlaysClick?: () => void;
    onMyFriendsPlaysClick?: () => void;
};

export default function MusicItemStatistics(props: MusicItemStatisticsProps) {
    const { numOfListeners, numOfPlays, numOfMyPlays, numOfMyFriendsPlays, onMyPlaysClick, onMyFriendsPlaysClick } = props;

    return (
        <div className={classes.musicStatistics}>
            <div>
                <div className={classes.playsInfo}>{numberShorten(numOfListeners)}</div>
                <sub>Listeners</sub>
            </div>
            <div>
                <div className={classes.playsInfo}>{numberShorten(numOfPlays)}</div>
                <sub>Plays</sub>
            </div>
            {Boolean(typeof numOfMyPlays === 'number' && numOfMyPlays > 0) && (
                <div onClick={onMyPlaysClick} style={{ cursor: onMyPlaysClick ? 'pointer' : '' }}>
                    <div className={classes.playsInfo}>{numberShorten(numOfMyPlays ?? 0)}</div>
                    <sub>My Plays</sub>
                </div>
            )}
            {Boolean(typeof numOfMyFriendsPlays === 'number' && numOfMyFriendsPlays > 0) && (
                <div onClick={onMyFriendsPlaysClick} style={{ cursor: onMyFriendsPlaysClick ? 'pointer' : '' }}>
                    <div className={classes.playsInfo}>{numberShorten(numOfMyFriendsPlays ?? 0)}</div>
                    <sub>My Friends</sub>
                </div>
            )}
        </div>
    );
}

/* User Statistics */

export type UserStatisticsProps = {
    numOfPlays: number;
    numOfArtists: number;
    registrationDate?: string;
};

export function UserStatistics(props: UserStatisticsProps) {
    const { numOfPlays, numOfArtists, registrationDate } = props;

    return (
        <div>
            <div className={'fs-120'}>
                <span className="fw-500">{numberShorten(numOfPlays)}</span>
                <span> Plays | </span>
                <span className="fw-500">{numberShorten(numOfArtists)}</span>
                <span> Artists</span>
            </div>
            {registrationDate && <div className="text-gray">{`Since ${registrationDate}`}</div>}
        </div>
    );
}
