import React, { useMemo } from 'react';

import { SecondsToTime } from '../../../../Classes/Utilities';

import Slider from '../../../Slider/Slider';

export type TimeSliderProps = {
    timeValue: number;
    timeDuration: number;
    customCSS?: React.CSSProperties;
    onTimeChange: (timeValue: number) => void;
};

function TimeSlider(props: TimeSliderProps) {
    const { timeValue, timeDuration, customCSS, onTimeChange } = props;

    const timeDurationStr = useMemo(() => {
        return timeDuration > 0 ? SecondsToTime(Math.round(timeDuration - 0.1)) : '00:00';
    }, [timeDuration]);

    const elapsedTimeStr = useMemo(() => {
        return timeValue > 0 ? SecondsToTime(Math.round(timeValue - 0.1)) : '00:00';
    }, [timeValue]);

    // Time slider % value (0 - 100)
    const sliderValue = useMemo(() => (timeDuration > 0 ? (timeValue / timeDuration) * 100 : 0), [timeDuration, timeValue]);

    return (
        <>
            <div>
                <Slider
                    curVal={sliderValue}
                    sliderHandlerShape="none"
                    sliderCustomCSS={customCSS}
                    onChange={(timePos, isDragged) => {
                        !isDragged && onTimeChange(timePos);
                    }}
                ></Slider>
            </div>
            <div>
                <div>{elapsedTimeStr}</div>
                <div>{timeDurationStr}</div>
            </div>
        </>
    );
}

export default TimeSlider;
