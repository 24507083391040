import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import usePageMetdata from '../../hooks/usePageMetdata';
import useDeviceData from '../../hooks/useDeviceData';

import { BELLINO_BASE_URL, capitalizeFirstLetter, FetchPageData } from '../../Classes/Utilities';
import { KeyValueString } from '../../Types/KeyValue';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import ChartList, { ChartItem } from '../../Components/Charts/ChartList/ChartList';
import Tabs, { TabItem } from '../../Components/Tabs/Tabs';
import Preloader from '../../Components/Preloader/Preloader';

import ChartFiltersDialog from './partials/ChartFiltersDialog';

import classes from './Charts.module.css';

function Charts(props: any) {
    usePageMetdata({ title: 'Charts', description: 'Explore artists, albums and tracks live charts' });

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { isMobileView } = useDeviceData();

    const timeFilter = searchParams.get('time') ?? 'week';
    const typeFilter = searchParams.get('type') ?? 'top_tracks';
    const genresFilter = searchParams.get('genre') ?? '';
    const minYearFilter = parseInt(searchParams.get('minYear') ?? '-1');
    const maxYearFilter = parseInt(searchParams.get('maxYear') ?? '-1');

    const [chartItems, setChartItems] = useState<ChartItem[]>([]);
    const [chartItemsOffset, setChartItemsOffset] = useState(0);
    const [isMoreToScroll, setIsMoreToScroll] = useState(true);
    const [isChartFiltersDialogVisible, setIsChartFiltersDialogVisible] = useState(false);

    const isBtnFilterActive = minYearFilter > 0 || maxYearFilter > 0 || genresFilter.length > 0;
    const isLoading = useRef<boolean>();
    const numOfChartItemsChunk = useRef(25);
    const numOfChartItems = chartItems.length;

    const buildParamsURL = (params: KeyValueString) => {
        let urlSearchParams = new URLSearchParams(window.location.search);

        Object.keys(params).forEach(paramName => {
            const value = params[paramName];

            if (value) {
                urlSearchParams.set(paramName, value);
            } else {
                urlSearchParams.delete(paramName);
            }
        });

        return `/charts?${urlSearchParams.toString()}`;
    };

    const timeTabItems: TabItem[] = [
        {
            text: 'Last Week Charts',
            href: buildParamsURL({ time: 'week', type: typeFilter }),
            isSelected: timeFilter === 'week'
        },
        {
            text: 'Last Month Charts',
            href: buildParamsURL({ time: 'month', type: typeFilter }),
            isSelected: timeFilter === 'month'
        },
        {
            text: 'Last Year Charts',
            href: buildParamsURL({ time: 'year', type: typeFilter }),
            isSelected: timeFilter === 'year'
        },
        {
            text: 'All Time Charts',
            href: buildParamsURL({ time: 'alltime', type: typeFilter }),
            isSelected: timeFilter === 'alltime'
        }
    ];

    const typeTabItems: TabItem[] = [
        {
            text: 'Top Tracks',
            href: buildParamsURL({ time: timeFilter, type: 'top_tracks' }),
            isSelected: typeFilter === 'top_tracks'
        },
        {
            text: 'Top Albums',
            href: buildParamsURL({ time: timeFilter, type: 'top_albums' }),
            isSelected: typeFilter === 'top_albums'
        },
        {
            text: 'Top Artists',
            href: buildParamsURL({ time: timeFilter, type: 'top_artists' }),
            isSelected: typeFilter === 'top_artists'
        }
    ];

    useEffect(() => {
        const handlePageScroll = () => {
            const scrollPos = window.scrollY + window.innerHeight;

            if (isMoreToScroll && scrollPos / document.body.clientHeight > 0.8) {
                setChartItemsOffset(numOfChartItems);
                numOfChartItemsChunk.current = 50;
            }
        };

        window.addEventListener('scroll', handlePageScroll);
        return () => window.removeEventListener('scroll', handlePageScroll);
    }, [numOfChartItems, isMoreToScroll]);

    useEffect(() => {
        setChartItemsOffset(0);
    }, [timeFilter, typeFilter]);

    useEffect(() => {
        if (!isLoading.current) {
            isLoading.current = true;

            if (chartItemsOffset === 0) {
                setChartItems([]);
            }

            const timeDays = timeFilter === 'week' ? 7 : timeFilter === 'month' ? 30 : timeFilter === 'year' ? 365 : -1;
            let type = typeFilter.replace('top_', '');
            type = type.substring(0, type.length - 1);

            FetchPageData(
                `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=GetCharts&type=${type}&minyear=${minYearFilter}&maxyear=${maxYearFilter}&genre=${encodeURIComponent(genresFilter)}&data=${timeDays}&length=${numOfChartItemsChunk.current}&offset=${chartItemsOffset}`
            ).then((response: any) => {
                if (response) {
                    const fetchedChartItems = response?.map((chartItem: any, cIndex: number) => {
                        const itemId = type === 'artist' ? chartItem.artistID : type === 'album' ? chartItem.albumID : chartItem.trackID;
                        const mainTitle =
                            type === 'artist' ? chartItem.artistName : type === 'album' ? chartItem.albumName : chartItem.trackName;
                        const mainTitleHref =
                            type === 'artist'
                                ? `/artist?aid=${chartItem.artistID}`
                                : type === 'album'
                                  ? `/album?aid=${chartItem.albumID}`
                                  : `/track?tid=${chartItem.trackID}`;
                        let subTitle = undefined;

                        if (type === 'artist') {
                            subTitle = <span className="fs-105 text-gray">{chartItem.origin}</span>;
                        } else {
                            const artistNames = chartItem.artistName?.split(';') ?? [];
                            const artistIDs = chartItem.artistID?.split(';') ?? [];
                            const numOfArtists: number = artistNames?.length ?? 0;

                            subTitle = artistNames.map((artistName: any, index: number) => {
                                const innerHTML = (
                                    <span key={index}>
                                        <span>{artistName}</span>
                                        {numOfArtists > index + 1 && <span>, </span>}
                                    </span>
                                );

                                if (artistIDs?.length && typeof artistIDs[index] !== 'undefined') {
                                    if (artistIDs[index] === 0) {
                                        return (
                                            <Link key={index} to={'/variousartists'}>
                                                Various Artists
                                            </Link>
                                        );
                                    } else {
                                        return (
                                            <Link key={index} to={`/artist?aid=${artistIDs[index]}`}>
                                                {innerHTML}
                                            </Link>
                                        );
                                    }
                                }

                                return innerHTML;
                            });
                        }

                        return {
                            id: itemId,
                            type: capitalizeFirstLetter(type),
                            mainTitleHref,
                            mainTitle,
                            subTitle,
                            date: chartItem.date,
                            position: chartItemsOffset + cIndex + 1,
                            image: `${BELLINO_BASE_URL}/${chartItem.photoPath}`,
                            score: chartItem.score,
                            tags: chartItem.genre?.split(';')?.map((genre: any, gIndex: number) => {
                                return {
                                    id: `${genre}_${gIndex}`,
                                    name: genre,
                                    href: `/genre?name=${encodeURIComponent(genre)}`
                                };
                            })
                        } as ChartItem;
                    });

                    if (chartItemsOffset > 0) {
                        setChartItems(oldArray => [...oldArray, ...fetchedChartItems]);
                    } else {
                        setChartItems(fetchedChartItems);
                    }

                    if (fetchedChartItems?.length === 0) {
                        setIsMoreToScroll(false);
                    }
                } else {
                    if (chartItemsOffset === 0) {
                        setChartItems([]);
                    }
                }

                isLoading.current = false;
            });
        }
    }, [timeFilter, typeFilter, minYearFilter, maxYearFilter, genresFilter, chartItemsOffset]);

    return (
        <AppMainContainer>
            <Section backgroundColor="white" withPadding={false}>
                <Tabs tabsItems={timeTabItems} viewMode="classic"></Tabs>
                <Tabs tabsItems={typeTabItems} viewMode="simple"></Tabs>
                <button
                    className={`${classes.filtersBtn} ${isBtnFilterActive ? classes.active : ''}`}
                    aria-label="Filters"
                    onClick={() => {
                        setIsChartFiltersDialogVisible(true);
                    }}
                >
                    {isMobileView ? '' : 'Filters'}
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                        <path d="M15 17h6v1h-6v-1zm-4 0H3v1h8v2h1v-5h-1v2zm3-9h1V3h-1v2H3v1h11v2zm4-3v1h3V5h-3zM6 14h1V9H6v2H3v1h3v2zm4-2h11v-1H10v1z"></path>
                    </svg>
                </button>
                <div style={{ padding: '1.25rem 2%' }}>
                    {chartItemsOffset === 0 && isLoading.current !== false ? (
                        <Preloader viewMode="Inline" animation="Search"></Preloader>
                    ) : (
                        <>
                            {chartItems.length > 0 ? <ChartList chartItems={chartItems}></ChartList> : <div>No data</div>}
                            {isMoreToScroll && isLoading.current !== false && <Preloader viewMode="Inline"></Preloader>}
                        </>
                    )}
                </div>
            </Section>

            <ChartFiltersDialog
                isVisible={isChartFiltersDialogVisible}
                onCloseDialog={() => setIsChartFiltersDialogVisible(false)}
                onFiltersChange={(minYear: number | undefined, maxYear: number | undefined, genres: string[]) => {
                    navigate(
                        buildParamsURL({ minYear: (minYear ?? '').toString(), maxYear: (maxYear ?? '').toString(), genre: genres?.[0] ?? '' })
                    );
                }}
                selectedMinYear={minYearFilter > 0 ? minYearFilter : undefined}
                selectedMaxYear={maxYearFilter > 0 ? maxYearFilter : undefined}
                selecteGenres={genresFilter ? [genresFilter] : undefined}
            ></ChartFiltersDialog>
        </AppMainContainer>
    );
}

export default Charts;
