import React, { useMemo } from 'react';

import { SecondsToTime } from '../../../../Classes/Utilities';

import Slider from '../../../Slider/Slider';

export type TimeSliderProps = {
    timeValue: number;
    timeDuration: number;
    customCSS?: React.CSSProperties;
    onTimeChange: (timeValue: number) => void;
};

function TimeSlider(props: TimeSliderProps) {
    const { timeValue, timeDuration, customCSS, onTimeChange } = props;

    const timeStr = useMemo(() => {
        const timeDurationStr = timeDuration > 0 ? SecondsToTime(Math.round(timeDuration - 0.1)) : '00:00';
        const elapsedTimeStr = timeValue > 0 ? SecondsToTime(Math.round(timeValue - 0.1)) : '00:00';
        return `${elapsedTimeStr} / ${timeDurationStr}`;
    }, [timeDuration, timeValue]);

    // Time slider % value (0 - 100)
    const sliderValue = useMemo(() => (timeDuration > 0 ? (timeValue / timeDuration) * 100 : 0), [timeDuration, timeValue]);

    return (
        <>
            <div>{timeStr}</div>
            <Slider
                curVal={sliderValue}
                sliderHandlerShape="none"
                sliderCustomCSS={customCSS}
                onChange={(timePos, isDragged) => {
                    !isDragged && onTimeChange(timePos);
                }}
            ></Slider>
        </>
    );
}

export default TimeSlider;
