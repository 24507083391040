import React, { ReactNode, useEffect, useMemo } from 'react';

import { MusicPlayerViewMode } from '../../../../Types/MusicPlayerViewMode';
import { PlayerStateMode } from '../../../../Types/Player';
import { TrackInfo } from '../../../../Types/Track';

import { disableScrollBar, enableScrollBar } from '../../../../Classes/Utilities';

import TimeSlider from './TimeSlider';
import PlayControlButtonsProps from '../PlayControlButtons';
import MusicPlayerTracklist from '../MusicPlayerTracklist';

import classes from './MusicPlayerMobile.module.css';

export type MusicPlayerMobileProps = {
    viewMode: MusicPlayerViewMode;
    volumeValue: number;
    timeValue: number;
    timeDuration: number;
    tracklist: TrackInfo[];
    currentTrackIndex: number;
    playStatus: PlayerStateMode;
    children: ReactNode;
    onPlayPause: () => void;
    onPreviousTrack: () => void;
    onNextTrack: () => void;
    onPlayTrack: (trackIndex: number) => void;
    onRemoveTrack: (trackIndex: number) => void;
    onPlayerViewModeChange: (viewMode: MusicPlayerViewMode) => void;
    onVolumeChange: (volumeValue: number, isMuted: boolean) => void;
    onTimeChange: (timeValue: number) => void;
} & JSX.IntrinsicAttributes;

function MusicPlayerMobile(props: MusicPlayerMobileProps) {
    const {
        viewMode,
        timeValue,
        timeDuration,
        tracklist,
        currentTrackIndex,
        playStatus,
        children,
        onPlayPause,
        onPreviousTrack,
        onNextTrack,
        onPlayTrack,
        onRemoveTrack,
        onPlayerViewModeChange,
        onTimeChange
    } = props;

    useEffect(() => {
        if (viewMode === 'normal') {
            disableScrollBar();
        } else {
            enableScrollBar();
        }
    }, [viewMode]);

    const viewHeight = useMemo(() => {
        if (viewMode === 'normal') return '100dvh';
        if (viewMode === 'small') return '2.7rem';

        return '0px';
    }, [viewMode]);

    return (
        <div className={`${classes.musicPlayer} ${viewMode === 'normal' ? classes.open : ''}`} style={{ height: viewHeight }}>
            {viewMode === 'normal' && (
                <button
                    className={classes.minimizeButton}
                    title={'Minimize'}
                    onClick={() => {
                        onPlayerViewModeChange('small');
                    }}
                >
                    <i className="fa-solid fa-chevron-down fa-lg"></i>
                </button>
            )}
            <div className={classes.youtubeContainer}>{children}</div>
            {viewMode === 'normal' && (
                <div className={classes.timeControl}>
                    <TimeSlider
                        timeValue={timeValue}
                        timeDuration={timeDuration}
                        customCSS={{ border: 0, backgroundColor: 'rgb(34, 34, 34)' }}
                        onTimeChange={onTimeChange}
                    ></TimeSlider>
                </div>
            )}
            <div className={classes.playerToolbar}>
                <div>{`Track ${currentTrackIndex + 1}/${tracklist.length}`}</div>
                <div>
                    <PlayControlButtonsProps
                        playStatus={playStatus}
                        onPlayPause={onPlayPause}
                        onPreviousTrack={onPreviousTrack}
                        onNextTrack={onNextTrack}
                    ></PlayControlButtonsProps>
                </div>
                <div>
                    <button
                        className={`fa fa-lg ${viewMode === 'normal' ? 'fa-compress' : 'fa-expand'}`}
                        title={viewMode === 'normal' ? 'Minimize' : 'Expand'}
                        onClick={() => {
                            onPlayerViewModeChange(viewMode === 'normal' ? 'small' : 'normal');
                        }}
                    ></button>
                </div>
            </div>
            {viewMode === 'normal' && (
                <div className={classes.playerTrackslist}>
                    <MusicPlayerTracklist
                        tracklist={tracklist}
                        currentTrackIndex={currentTrackIndex}
                        onPlayTrack={onPlayTrack}
                        onRemoveTrack={onRemoveTrack}
                        onLinkClick={() => {
                            onPlayerViewModeChange('small');
                        }}
                    ></MusicPlayerTracklist>
                </div>
            )}
        </div>
    );
}

export default MusicPlayerMobile;
