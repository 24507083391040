import React, { useEffect, useState } from 'react';

import { BELLINO_BASE_URL, DurationStrToSeconds, FetchApiData } from '../../../Classes/Utilities';

//import Button from '../../../Components/Button/Button';
import Title from '../../../Components/Title/Title';
import Button from '../../../Components/Button/Button';

type NewAlbumInfo = {
    name: string;
    date?: Date;
    artists: string[];
    genres?: string[];
    tracks: AlbumTrack[];
};

type AlbumTrack = {
    name: string;
    duration: number;
    durationStr: string;
    position: number;
    cdNumber?: number;
    artists?: string[];
};

function AddAlbum() {
    //const [albumInfo, setAlbumInfo] = useState<NewAlbumInfo>();
    const [albumTracks, setAlbumTracks] = useState<AlbumTrack[]>([]);
    const [albumDataText, setAlbumDataText] = useState<string>('');

    useEffect(() => {
        const parsedTracks = parseAlbumTracks(albumDataText);
        setAlbumTracks(parsedTracks);
    }, [albumDataText]);

    /*const parseAlbumTracks = (albumDataText: string): AlbumTrack[] => {
        const trackRegex = /^\d+\.\s*(.+?)\s+(\d{2}:\d{2})$/gm;
        const tracks: AlbumTrack[] = [];
        let match: RegExpExecArray | null;

        while ((match = trackRegex.exec(albumDataText)) !== null) {
            const [, name, durationStr] = match;
            tracks.push({ name, durationStr });
        }

        return tracks;
    };*/

    const parseAlbumTracks = (albumDataText: string): AlbumTrack[] => {
        const lines = albumDataText.split(/\n/); // Split input into lines
        const durationRegex = /\d{1,2}:\d{2}/; // Match mm:ss
        const tracks: AlbumTrack[] = [];

        let isPatternDetermined = false;
        let pattern: 'name-duration' | 'duration-name' | null = null;

        for (const line of lines) {
            const trimmedLine = line.trim();

            // Try to extract duration and name based on current pattern
            const durationMatch = durationRegex.exec(trimmedLine);
            if (!durationMatch) continue; // Skip lines without duration

            const durationStr = durationMatch[0];
            let name = '';

            if (!isPatternDetermined) {
                // Check where the duration appears relative to the name
                const beforeDuration = trimmedLine.slice(0, durationMatch.index).trim();
                const afterDuration = trimmedLine.slice(durationMatch.index + durationStr.length).trim();

                if (beforeDuration) {
                    pattern = 'name-duration';
                    name = beforeDuration;
                } else if (afterDuration) {
                    pattern = 'duration-name';
                    name = afterDuration;
                }

                isPatternDetermined = true;
            } else {
                // Apply the determined pattern
                if (pattern === 'name-duration') {
                    name = trimmedLine.slice(0, durationMatch.index).trim();
                } else if (pattern === 'duration-name') {
                    name = trimmedLine.slice(durationMatch.index + durationStr.length).trim();
                }
            }

            // Ensure name is unique
            if (name) {
                const trackIndex = tracks.length + 1;

                // Check if the name starts with the current index and clean it up if it does
                if (name.trim().startsWith(`${trackIndex}`)) {
                    name = name.replace(/^\d+[.\s\t]+/, '').trim();
                }

                tracks.push({ position: trackIndex, name, durationStr, duration: DurationStrToSeconds(durationStr) });
            }
        }

        return tracks;
    };

    const onAddAlbum = async () => {
        const albumToAdd: NewAlbumInfo = {
            name: (document.getElementById('album_name') as HTMLInputElement).value,
            date: new Date((document.getElementById('album_date') as HTMLInputElement).value),
            genres: (document.getElementById('album_genres') as HTMLInputElement).value.split(';'),
            artists: (document.getElementById('album_artists') as HTMLInputElement).value.split(';'),
            tracks: albumTracks
        };

        console.log(albumToAdd);

        if (0 && albumToAdd.name.trim().length > 0 && albumToAdd.artists.length > 0 && albumToAdd.tracks.length > 0) {
            const fetchPromises = albumToAdd.tracks.map(albumTrack =>
                FetchApiData(`${BELLINO_BASE_URL}/api/tracks?action=AddTrackData`, 'json', 'POST', {
                    Artists: (albumTrack.artists || []).concat(albumToAdd.artists),
                    AlbumArtists: albumToAdd.artists,
                    aname: albumToAdd.name,
                    tname: albumTrack.name,
                    tnum: albumTrack.position,
                    acd: albumTrack.cdNumber || 1,
                    length: albumTrack.duration,
                    genre: [],
                    year: albumToAdd.date ? albumToAdd.date.getFullYear() : undefined,
                    month: albumToAdd.date ? albumToAdd.date.getMonth() + 1 : undefined,
                    day: albumToAdd.date ? albumToAdd.date.getDay() + 1 : undefined
                })
            );

            const res = await Promise.all(fetchPromises);
        }
    };

    return (
        <>
            <Title>Add Album</Title>
            <div style={{ display: 'flex', gap: '1rem' }}>
                <div style={{ flex: 1 }}>
                    <div className="mb-100" style={{ display: 'flex', gap: '.5rem' }}>
                        <input type="text" id="album_name" placeholder="Album Name"></input>
                        <input type="text" id="album_genres" placeholder="Album Genres"></input>
                        <input type="text" id="album_date" placeholder="Album Date"></input>
                        <input type="text" id="album_artists" placeholder="Album Artists"></input>
                    </div>
                    <textarea
                        style={{ width: '100%', height: '300px' }}
                        placeholder="Paste album tracks data here..."
                        value={albumDataText}
                        onChange={e => setAlbumDataText(e.target.value)}
                    />
                    <Button className="mt-100" viewMode="fancy" btnColor="blue" onClick={onAddAlbum}>
                        Add Album
                    </Button>
                </div>
                <div style={{ flex: 1 }}>
                    <ul>
                        {albumTracks.map((track, index) => (
                            <li key={index}>
                                <div>
                                    <strong>{track.name}</strong>
                                </div>
                                <div>{track.durationStr}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default AddAlbum;
